












































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import {
  SfLoader,
} from '@storefront-ui/vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue'
import Jetinput from '~/components/JetUi/atoms/Input/JetInput.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ForgotPasswordFormFields } from '../../LoginModal/forms/types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    Jetinput,
    JetUiButton,
    SfLoader,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<ForgotPasswordFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<ForgotPasswordFormFields>({ ...props.form });
    const username = ref(formCopy.value.username || '');

    const syncUsernameWithSessionStorage = () => {
      sessionStorage.setItem(
        'UserEmail',
        JSON.stringify({ valor: username.value, expira: Date.now() + 3600000 })
      );
    };

    const onSubmit = () => {
      syncUsernameWithSessionStorage();
      emit('submit', formCopy.value);
    };

    watch(
      () => formCopy.value.username,
      (newUsername) => {
        username.value = newUsername;
        syncUsernameWithSessionStorage();
      }
    );

    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
        username.value = formCopy.value.username || '';
        syncUsernameWithSessionStorage();
      },
      { immediate: true, deep: true }
    );

    return { formCopy, onSubmit };
  },
});
