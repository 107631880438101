












































import {
  defineComponent, PropType, ref, watch,
} from '@nuxtjs/composition-api';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { SfButton } from '@storefront-ui/vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import JetInput from '~/components/JetUi/atoms/Input/JetInput.vue';
import CloudFlareCaptcha from '~/components/JetUi/atoms/Captcha/CloudFlareCaptcha.vue';
import { LoginFormFields, FormName } from '../../LoginModal/forms/types';

// Extend vee-validate rules
extend('email', {
  ...email,
  message: 'Email inválido',
});

extend('required', {
  ...required,
  message: 'Este campo é obrigatório',
});

export default defineComponent({
  name: 'LoginForm',
  components: {
    SfButton,
    ValidationProvider,
    ValidationObserver,
    JetUiButton,
    JetInput,
    CloudFlareCaptcha,
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const buttonText = ref('Entrar');

    const formDisabled = ref(true);
    const formCopy = ref<LoginFormFields>({ email: '', password: '' });
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const handleButtonClick = () => {
      if (buttonText.value === 'Entrar') {
        buttonText.value = 'Autenticando...';
      }
      emit('submit', formCopy.value);
    };

    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    const setButtonDisabled = (result: boolean) => {
      formDisabled.value = result
    }

    return {
      formCopy,
      changeForm,
      formDisabled,
      setButtonDisabled,
      buttonText,
      handleButtonClick
    };
  },
});
