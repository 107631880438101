








































import {
  ref,
  defineComponent,
  computed,
  useContext,
  onBeforeMount,
  watch,
  inject,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfBar,
  SfButton,
  SfIcon,
} from '@storefront-ui/vue';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiState } from '~/composables/useUiState';
import LoginRegisterSwitch from './forms/LoginRegisterSwitch.vue';
import RegisterForm from './forms/RegisterForm.vue';
import ForgotPasswordForm from './forms/ForgotPasswordForm.vue';
import ForgotPasswordThankYou from './forms/ForgotPasswordThankYou.vue';
import {
  ForgotPasswordFormFields, JetFormName, JetRegisterFormFields, LoginFormFields,
} from '../LoginModal/forms/types';
import LoginForm from './forms/LoginForm.vue';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfBar,
    SfButton,
    SfIcon,
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou,
    LoginRegisterSwitch,
  },
  setup(_, { emit }) {
    const { isLoginModalOpen } = useUiState();
    const {
      register,
      login,
      loading,
      error: userError,
      user,
      load
    } = useUser();

    const { load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();
    const { request: resetPassword, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();
    const { updateUsername } = inject<{updateUsername: (name: string) => void}>('username');

    const currentlyDisplayedForm = ref<JetFormName>('login');
    const barTitle = computed(() => {
      const mapFormNameToTopBarLabel: Record<JetFormName, string> = {
        login: 'Sign in',
        register: 'Register',
        forgotPassword: 'Reset Password',
        forgotPasswordThankYou: 'Thank you',
        switch: 'Switch',
      };

      return mapFormNameToTopBarLabel[currentlyDisplayedForm.value];
    });

    watch(currentlyDisplayedForm, (newValue, oldValue) => {
      if (newValue === 'login') {
        document.documentElement.style.setProperty('--modal-size', '65rem')
      } else {
        document.documentElement.style.setProperty('--modal-size', '45rem')
      }
    })

    const isBlocked = ref(false);

    onBeforeMount(() => {
      const parts = window.location.href.split('/');
      isBlocked.value = parts.includes('checkout');
      document.documentElement.style.setProperty('--modal-size', '65rem');
    })

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
    });
    const registerForm = ref<JetRegisterFormFields>({
      rg_ie: '',
      isento: false,
      cnpj: '',
      email: '',
      celular: '',
      password: '',
      confirmPassword: '',
      date_of_birth: '',
      taxvat: '',
      gender: 4,
      firstName: '',
      lastName: '',
      is_subscribed: false,
    });

    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: '',
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    const getRecaptchaInfo = async (isRecaptchaOn: boolean): Promise<{ token: string | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return { token: await $recaptcha.getResponse(), cleanup: () => { $recaptcha.reset(); } };
      }
      return { token: null, cleanup: () => { } };
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await login({ user: { ...form, ...(token ? { recaptchaToken: token } : {}) } });
      cleanup();
      if (!userError.value.login) {
        await Promise.all([loadItemsCount(), loadCart()]);
        await load();
        updateUsername(user.value ? `${user.value?.firstname}` : '');
        emit('close');
      }
    };

    const onRegisterFormSubmit = async (form: JetRegisterFormFields) => {
      registerForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      try {
        await register({
          user: {
            email: form.email,
            phone: form.celular,
            password: form.confirmPassword,
            prefix: '',
            suffix: '',
            date_of_birth: form.date_of_birth,
            taxvat: form.taxvat,
            gender: form.gender,
            firstname: form.firstName,
            middlename: '',
            is_subscribed: form.is_subscribed,
            lastname: form.lastName,
            // ...(token ? { recaptchaToken: token } : {}),
          },
        });
        if (!userError.value.register) {
          emit('close');
          await Promise.all([loadItemsCount(), loadCart()]);
        } else {
          console.error('Erro ao registrar usuário:', userError.value.register);
        }
      } catch (error) {
        console.error('Erro ao registrar usuário:', error);
      } finally {
        cleanup();
      }
    };

    const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
      forgotPasswordForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await resetPassword({ email: form.username, ...(token ? { recaptchaToken: token } : {}) });
      cleanup();
      currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    return {
      isLoginModalOpen,

      userError,
      loading,

      forgotPasswordError,
      forgotPasswordLoading,

      currentlyDisplayedForm,
      barTitle,

      loginForm,
      registerForm,
      forgotPasswordForm,

      isRecaptchaEnabled,
      isBlocked,

      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
    };
  },
});
