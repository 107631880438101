








































































import { defineComponent, ref, computed, useContext } from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfHeading } from '@storefront-ui/vue';
import { useForgotPassword } from '~/composables';
import Jetinput from '~/components/JetUi/atoms/Input/JetInput.vue'

const customerPasswordRegExp = /^(?=.*[A-Za-z])(?=.*\d|.*[!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~])(?=.{8,})/;
const invalidPasswordMsg = 'Password must be at least 8 characters long and contain at least one letter, one number or one special character.';

export default defineComponent({
  name: 'ForgotPasswordThankYou',
  components: {
    SfButton,
    SfLoader,
    Jetinput,
    SfHeading,
  },

  setup() {
    const { result, setNew, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();
    const passwordMatchError = ref<string | null>(null);
    const tokenError = ref<string | null>(null);
    const newPasswordError = ref<string | null>(null);
    const repeatPasswordError = ref<string | null>(null);
    const emailUser = ref('');
    const email = JSON.parse(sessionStorage.getItem('UserEmail'));
    if (email && email.expira > Date.now()) {
      emailUser.value =  email.valor
    } else {
      sessionStorage.removeItem('UserEmail');
    }
    const form = ref({
      token: '',
      newPassword: '',
      repeatPassword: '',
    });

    const isPasswordChanged = computed(() => result.value.setNewPasswordResult);

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);


    const isSubmitDisabled = computed(() => {
      return (
        !form.value.token ||
        !form.value.newPassword ||
        !form.value.repeatPassword ||
        form.value.newPassword !== form.value.repeatPassword ||
        !customerPasswordRegExp.test(form.value.newPassword)
      );
    });

    const setNewPassword = async () => {
      passwordMatchError.value = null;
      tokenError.value = null;
      newPasswordError.value = null;
      repeatPasswordError.value = null;

      if (!form.value.token) {
        tokenError.value = 'Token is required';
        return;
      }

      if (!form.value.newPassword) {
        newPasswordError.value = 'New password is required';
        return;
      }

      if (!customerPasswordRegExp.test(form.value.newPassword)) {
        newPasswordError.value = invalidPasswordMsg;
        return;
      }

      if (form.value.newPassword !== form.value.repeatPassword) {
        passwordMatchError.value = 'Passwords do not match';
        return;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      try {
        const recaptchaToken = isRecaptchaEnabled.value ? await $recaptcha.getResponse() : undefined;

        console.log('Attempting to set new password with:', {
          tokenValue: form.value.token,
          newPassword: form.value.newPassword,
          recaptchaToken,
        });

        const response = await setNew({
          tokenValue: form.value.token,
          newPassword: form.value.newPassword,
          email: emailUser.value,
          recaptchaToken,
        });

        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      } catch (e) {
        console.error('Error setting new password:', e);
      }
    };

    return {
      isPasswordChanged,
      form,
      setNewPassword,
      forgotPasswordLoading,
      forgotPasswordError,
      passwordMatchError,
      tokenError,
      newPasswordError,
      repeatPasswordError,
      isRecaptchaEnabled,
      isSubmitDisabled,
    };
  },
});
