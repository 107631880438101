

















import { defineComponent, ref } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'Warning',
    props: {
        text: {
            type: String,
            default: '',
        }
    },
    setup() {
        const modalClosed = ref(false);
        const closeWarning = () => {
            modalClosed.value = true;
        };

        return {
            modalClosed,
            closeWarning
        };
    }
});
