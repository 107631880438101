




import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useCaptcha } from '~/composables'

export default defineComponent({
    name: 'CloudFlareCaptcha',
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { siteKey, validateCaptcha } = useCaptcha();

        onMounted(() => {
            const scriptSrc = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
            let script = Array.from(document.getElementsByTagName('script')).find(script => script.src === scriptSrc);

            if (!script) {
                script = document.createElement('script');
                script.src = scriptSrc;
                script.async = true;
            }

            script.onload = () => {
                const captchaElement = document.querySelector('.cf-turnstile');

                if (captchaElement) {
                    captchaElement.setAttribute('data-callback', 'onTurnstileSuccess');

                    (window as any).onTurnstileSuccess = async(token: string) => {
                        emit('result', await validateCaptcha(token, props.visible));
                    };
                }
            };

            document.head.appendChild(script);

            if (!props.visible) {
                document.documentElement.style.setProperty('--display', 'none')
            } else {
                document.documentElement.style.setProperty('--display', 'block')
            }
        });

        return {
            siteKey
        };
    }
});
