

























import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { required, email } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import { LoginFormFields, FormName } from '../../LoginModal/forms/types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'LoginRegisterSwitch',
  components: {
    JetUiButton,
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<LoginFormFields>({ ...props.form });

    watch(() => props?.form, (newForm) => {
      formCopy.value = { ...newForm };
    }, { immediate: true, deep: true });

    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    return {
      formCopy,
      changeForm,
    };
  },
});
