




























import { defineComponent, ref, PropType, watch } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'JetInput',
  components: {
    SvgImage,
  },
  props: {
    label: {
      type: String as PropType<string>,
      default: '',
    },
    typeValidation: {
      type: String as PropType<string>,
      default: '',
    },
    type: {
      type: String as PropType<string>,
      default: '',
    },
    id: {
      type: String as PropType<string>,
      default: '',
    },
    value: {
      type: [String, Number, Date] as PropType<string | number | Date>,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hasShowPassword: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string>,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    maxLength: {
      type: Number,
      default: -1
    },
    debounceTime: {
      type: Number,
      default: 500
    }
  },
  setup(props, { emit }) {
    const isPasswordVisible = ref(false);
    const inputType = ref(props.type);

    watch(() => props.type, (newType) => {
      if (newType !== 'password') {
        inputType.value = newType;
      }
    });

    const togglePasswordVisibility = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    const handleInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      let { value } = input;

      switch (props.typeValidation) {
        case 'cpf': {
          value = value.replace(/\D/g, '').slice(0, 11);
          input.value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          break;
        }
        case 'cnpj': {
          value = value.replace(/\D/g, '').slice(0, 14);
          input.value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          break;
        }
        case 'cep': {
          value = value.replace(/\D/g, '').slice(0, 8);
          input.value = value.replace(/(\d{5})(\d{1,3})/, '$1-$2');
          break;
        }
        case 'phone': {
          value = value.replace(/\D/g, '').slice(0, 11);
          input.value = value.replace(/(\d{2})(\d{1,5})(\d{1,4})/, '($1) $2-$3');
          break;
        }
        case 'card-number': {
          value = value.replace(/[^\d\s]/g, '').replace(/\D/g, '').slice(0, 16);
          input.value = value.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
          break;
        }
        default:
          break;
      }

      emit('input', input.value);

      let timeout;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        emit('debouce');
      }, props.debounceTime);
    };

    const handleFocus = () => {
      emit('focus');
    };

    const handleBlur = () => {
      emit('blur');
    };

    return {
      isPasswordVisible,
      inputType,
      handleInput,
      handleFocus,
      handleBlur,
      togglePasswordVisibility
    };
  },
});
