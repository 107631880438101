

















































































































































































import {
  defineComponent, PropType, ref, watch, computed,
} from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { SfCheckbox, SfButton } from '@storefront-ui/vue';
import JetInput from '~/components/JetUi/atoms/Input/JetInput.vue';
import CloudFlareCaptcha from '~/components/JetUi/atoms/Captcha/CloudFlareCaptcha.vue';
import JetUiButton from '~/components/JetUi/atoms/JetUiButton/JetUiButton.vue';
import { useValidator } from '~/composables';
import axios from 'axios';
import { JetFormName, JetRegisterFormFields } from '../../LoginModal/forms/types';
import Warning from '~/components/JetUi/atoms/Warning/Warning.vue';

export default defineComponent({
  components: {
    ValidationObserver,
    JetUiButton,
    SfCheckbox,
    JetInput,
    SfButton,
    CloudFlareCaptcha,
    Warning,
  },
  props: {
    form: {
      type: Object as PropType<JetRegisterFormFields>,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const modelCheck = ref(false);
    const modelAgree = ref(false);
    const formDisabled = ref(true);
    const formCopy = ref<JetRegisterFormFields>();
    const emailError = ref<string | null>(null);
    const { validateCNPJ, validateCPF } = useValidator();

    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });

    watch(modelCheck, () => {
      formCopy.value = {
        rg_ie: '',
        isento: false,
        cnpj: '',
        password: '',
        confirmPassword: '',
        email: '',
        celular: '',
        date_of_birth: '',
        taxvat: '',
        gender: 4,
        firstName: '',
        lastName: '',
        is_subscribed: false,
      };
    });

    const validateFields = (requiredFields: string[], form: JetRegisterFormFields) => requiredFields.some((field) => !form[field]) || form.password !== form.confirmPassword;

    const pessoaFisicaValidatedForm = computed(() => {
      const requiredFields = ['firstName', 'lastName', 'date_of_birth', 'taxvat', 'password', 'celular', 'email'];
      if (formCopy.value.gender == 4) {
        return true;
      }

      return validateFields(requiredFields, formCopy.value) || !validateCPF(formCopy.value.taxvat);
    });

    const pessoaJuridicaValidatedForm = computed(() => {
      const requiredFields = ['firstName', 'rg_ie', 'taxvat', 'email', 'celular', 'password'];
      return validateFields(requiredFields, formCopy.value) || !validateCNPJ(formCopy.value.taxvat);
    });

    const changeForm = (formToNavigateTo: JetFormName) => {
      emit('change-form', formToNavigateTo);
    };

    const setTradeName = async () => {
      formCopy.value.firstName = '';
      formCopy.value.lastName = '';
      if (validateCNPJ(formCopy.value.taxvat)) {
        const cnpj = formCopy.value.taxvat.replace(/\D/g, '');
        await axios.get(`http://132.226.245.223/JetServicos/apiservicos/apijetservico/wsobterdadosparceiro?inDocumento=${cnpj}`).then((response) => {
          const name = response.data["SDT_ReceitaWS"]["nome"] as string;
          formCopy.value.firstName = name;
        }).catch((error) => {
          console.log(error);
        });
      }
    }

    const submitForm = async () => {
      if (formCopy.value.password !== formCopy.value.confirmPassword) {
        alert('Senhas não coincidem');
        return;
      }
      if (!modelCheck.value) {
        formCopy.value.gender = null;

        const listNames = formCopy.value.firstName.split(' ');
        formCopy.value.firstName = listNames.slice(0, -1).join(" ");
        formCopy.value.lastName = listNames[listNames.length - 1];
      }

      emit('submit', formCopy.value);
    };

    const isPasswordValid = (password: string): boolean => {
      let conditionsMet = 0;

      if (/[A-Z]/.test(password)) conditionsMet++;
      if (/[a-z]/.test(password)) conditionsMet++;
      if (/[^a-zA-Z0-9]/.test(password)) conditionsMet++;
      if (/[0-9]/.test(password)) conditionsMet++;

      return (conditionsMet >= 3 && password.length >= 8) || password.length == 0;
    };

    const setButtonDisabled = (result: boolean) => {
      formDisabled.value = result;
    };

    return {
      modelCheck,
      modelAgree,
      formCopy,
      emailError,
      submitForm,
      changeForm,
      formDisabled,
      setButtonDisabled,
      validateCPF,
      validateCNPJ,
      pessoaFisicaValidatedForm,
      pessoaJuridicaValidatedForm,
      setTradeName,
      isPasswordValid
    };
  },
});
